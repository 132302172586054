<template>
  <div class="container-loading">
    <div class="container-loading-item" >
        <img src="/images/loading.png" class="loading" style="width: 60px; height: 60px;" alt="Precompro.com">
        <p >Cargando...</p>
        <p >Estamos buscando los restaurantes.</p>
      </div>
  </div>
</template>
<script>
export default {
  name: 'recommendedLoadingComponent'
};
</script>
<style lang="scss">
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-family: 'Source Sans Pro';
    animation: fade 1s;
    height: 100%;
    justify-content: center;
  }
}
</style>
