<template>
  <div class="container-hours">
    <div class="hours">
      <div class="hours-info">
        <div class="hours-info-title">Paso 3</div>
        <div class="hours-info-subtitle">Selecciona las horas disponibles</div>
      </div>
      <div class="hours-operation" v-if="(timeAtention.length == 0 && availability.length == 0 && !dateReservation)">
        <span class="hours-operation-title" v-if="!processingHours">Para ver las horas disponibles debe seleccionar una fecha</span>
        <i class="fa fa-spinner fa-spin white" v-else></i>
      </div>
      <div class="hours-operation" v-if="timeAtention.length == 0 && availability.length > 0 && dateReservation" @click="getTime()">
        <span class="hours-operation-title" style="width: auto; font-weight: 700;">{{ dateReservation | moment('h:mm a') }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none" style="margin-left: 20px;">
          <path d="M13.7108 0.295798C13.6178 0.202069 13.5072 0.127675 13.3854 0.0769065C13.2635 0.026138 13.1328 0 13.0008 0C12.8688 0 12.7381 0.026138 12.6162 0.0769065C12.4944 0.127675 12.3838 0.202069 12.2908 0.295798L7.71079 4.87579C7.61783 4.96952 7.50723 5.04391 7.38537 5.09468C7.26351 5.14545 7.13281 5.17159 7.00079 5.17159C6.86878 5.17159 6.73808 5.14545 6.61622 5.09468C6.49436 5.04391 6.38376 4.96952 6.29079 4.87579L1.71079 0.295798C1.61783 0.202069 1.50723 0.127675 1.38537 0.0769065C1.26351 0.026138 1.1328 0 1.00079 0C0.868781 0 0.738075 0.026138 0.616216 0.0769065C0.494357 0.127675 0.383756 0.202069 0.290792 0.295798C0.104542 0.48316 0 0.736611 0 1.0008C0 1.26498 0.104542 1.51843 0.290792 1.70579L4.88079 6.29579C5.44329 6.85759 6.20579 7.17314 7.00079 7.17314C7.7958 7.17314 8.55829 6.85759 9.12079 6.29579L13.7108 1.70579C13.897 1.51843 14.0016 1.26498 14.0016 1.0008C14.0016 0.736611 13.897 0.48316 13.7108 0.295798Z" fill="white"/>
        </svg>
      </div>
      <div class="hours-operation" v-if="timeAtention.length == 0 && availability.length == 0 && dateReservation" @click="getTime()">
        <span class="hours-operation-title" style="width: auto; font-weight: 700;">{{ dateReservation | moment('h:mm a') }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none" style="margin-left: 20px;">
          <path d="M13.7108 0.295798C13.6178 0.202069 13.5072 0.127675 13.3854 0.0769065C13.2635 0.026138 13.1328 0 13.0008 0C12.8688 0 12.7381 0.026138 12.6162 0.0769065C12.4944 0.127675 12.3838 0.202069 12.2908 0.295798L7.71079 4.87579C7.61783 4.96952 7.50723 5.04391 7.38537 5.09468C7.26351 5.14545 7.13281 5.17159 7.00079 5.17159C6.86878 5.17159 6.73808 5.14545 6.61622 5.09468C6.49436 5.04391 6.38376 4.96952 6.29079 4.87579L1.71079 0.295798C1.61783 0.202069 1.50723 0.127675 1.38537 0.0769065C1.26351 0.026138 1.1328 0 1.00079 0C0.868781 0 0.738075 0.026138 0.616216 0.0769065C0.494357 0.127675 0.383756 0.202069 0.290792 0.295798C0.104542 0.48316 0 0.736611 0 1.0008C0 1.26498 0.104542 1.51843 0.290792 1.70579L4.88079 6.29579C5.44329 6.85759 6.20579 7.17314 7.00079 7.17314C7.7958 7.17314 8.55829 6.85759 9.12079 6.29579L13.7108 1.70579C13.897 1.51843 14.0016 1.26498 14.0016 1.0008C14.0016 0.736611 13.897 0.48316 13.7108 0.295798Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div class="hours-times" v-if="timeAtention.length > 0 && !processingHours && showHours && source == ''">
      <div class="hours-times-container">
        <a
          :class="{'select-hour': time.date === dateReservation, 'display_none' :time.date <= now}"
          :key="'hour-' + i"
          @click="setSelectedHour(time.date)"
          class="hours-times-container-items"
          href="javascript:void(0)"
          v-for="(time, i) in timeAtention"
        >
          <span>{{ time.date | moment('h:mm a') }}</span>
        </a>
      </div>
      <div class="hours-times-description">
        <div class="hours-times-description-item">
          <div class="active"></div>
          <span>Horas disponibles</span>
        </div>
        <div class="hours-times-description-item">
          <div class="inactive"></div>
          <span>Horas no disponibles</span>
        </div>
      </div>
    </div>
    <div class="hours-times" v-if="availability.length > 0 && !processingHours && showHours && (source === 'recommended' || source === 'vendors')">
      <div class="hours-times-container">
        <a
          :class="{'select-hour': time.date === dateReservation, disabled: !time.status, 'display_none' :time.date <= now}"
          :key="'hour#' + i"
          @click="setSelectedHour(time.date)"
          class="hours-times-container-items"
          href="javascript:void(0)"
          v-for="(time, i) in availability"
        >
          <span>{{ time.date | moment('h:mm a') }}</span>
        </a>
      </div>
      <div class="hours-times-description">
        <div class="hours-times-description-item">
          <div class="active"></div>
          <span>Horas disponibles</span>
        </div>
        <div class="hours-times-description-item">
          <div class="inactive"></div>
          <span>Horas no disponibles</span>
        </div>
      </div>
    </div>
    <div class="hours-times" v-if="availability.length > 0 && !processingHours && showHours && source === 'availability' && !hourSelected">
      <div class="hours-times-container">
        <a
          :class="{'select-hour': time.date === dateReservation, disabled: !time.status, 'display_none' :time.date <= now }"
          :key="'hour-#' + i"
          @click="setSelectedHour(time.date)"
          class="hours-times-container-items"
          href="javascript:void(0)"
          v-for="(time, i) in availability"
        >
          <span>{{ time.date | moment('h:mm a') }}</span>
        </a>
      </div>
      <div class="hours-times-description">
        <div class="hours-times-description-item">
          <div class="active"></div>
          <span>Horas disponibles</span>
        </div>
        <div class="hours-times-description-item">
          <div class="inactive"></div>
          <span>Horas no disponibles</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import precomproTypes from '@/types/precompro';
const ONE_HOURS = 1000 * 60 * 60 * 1;
export default {
  name: 'hoursComponent',
  props: ['isEdit'],
  data () {
    return {
      now: parseInt(this.$moment().format('x')) + ONE_HOURS,
      hourSelected: false
    };
  },
  methods: {
    getTime () {
      if (this.source === '') {
        this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, true);
        this.$store.dispatch({ type: 'global:getTimeAtention', data: { date: parseInt(this.$moment(this.date + ' 00:00:00').format('x')) } }).then(data => {
          this.$store.commit(precomproTypes.mutations.setReservationTimeAtention, data);
        }).finally(() => {
          this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
          this.$store.commit(precomproTypes.mutations.setReservationShowHours, true);
        });
      } else {
        this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, true);
        this.$store.dispatch({ type: 'precompro:searchAvailability', data: { people: this.people, date: this.date, vendorId: this.vendor.vendorId, groupId: 4 } }).finally(() => {
          this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
          this.$store.commit(precomproTypes.mutations.setReservationShowHours, true);
        }).catch(() => {
          this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
          this.$store.commit(precomproTypes.mutations.setReservationDate, null);
          this.$store.commit(precomproTypes.mutations.setReservationHours, null);
        });
      }
    },
    setSelectedHour (hour) {
      this.$store.commit(precomproTypes.mutations.setReservationHours, hour);
      if (this.source !== '') {
        const data = {
          people: this.people,
          date: this.dateReservation,
          vendorId: this.vendor.vendorId
        };
        this.$store.commit(precomproTypes.mutations.setReservation, data);
      }
      this.$store.commit(precomproTypes.mutations.setReservationTimeAtention, []);
      this.$store.commit(precomproTypes.mutations.setReservationShowHours, false);
      this.$store.commit(precomproTypes.mutations.setReservationShowSearchButton, true);
      this.searchAvailability();
    },
    updateAvailability () {
      if (this.source !== '' && this.date !== null) {
        this.$store.commit(precomproTypes.mutations.setReservationAvailability, []);
        this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, true);
        this.$store.dispatch({ type: 'precompro:searchAvailability', data: { people: this.people, date: this.date, vendorId: this.vendor.vendorId, groupId: 4 } })
          .finally(() => {
            this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
            this.$store.commit(precomproTypes.mutations.setReservationShowHours, true);
            if (this.source === 'availability' && this.isEdit === false) {
              this.hourSelected = true;
            } else {
              this.hourSelected = false;
            }
          }).catch(() => {
            this.$store.commit(precomproTypes.mutations.setReservationShowHours, false);
            this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
            this.$store.commit(precomproTypes.mutations.setReservationDate, null);
            this.$store.commit(precomproTypes.mutations.setReservationHours, null);
          });
      } else {
        if (this.date) {
          this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, true);
          this.$store.dispatch({ type: 'global:getTimeAtention', data: { date: parseInt(this.$moment(this.date + ' 00:00:00').format('x')) } }).then(data => {
            this.$store.commit(precomproTypes.mutations.setReservationTimeAtention, data);
          }).finally(() => {
            this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
            this.$store.commit(precomproTypes.mutations.setReservationShowHours, true);
          });
        }
      }
    },
    searchAvailability () {
      this.$store.commit(precomproTypes.mutations.setReservationVendors, []);
      this.$store.commit(precomproTypes.mutations.setReservationAvailability, []);
      this.$store.commit(precomproTypes.mutations.setReservationSearchingAvailability, true);
      this.$store.dispatch({ type: 'precompro:searchAvailability', data: { people: this.people, date: this.dateReservation, groupId: 4 } })
        .then(() => {
          this.$store.commit(precomproTypes.mutations.setReservationSearchingAvailability, false);
        }).catch(() => {
          this.$store.commit(precomproTypes.mutations.setReservationAvailability, []);
          this.$store.commit(precomproTypes.mutations.setReservationSearchingAvailability, false);
        });
    }
  },
  watch: {
    people () {
      this.updateAvailability();
    },
    date () {
      this.updateAvailability();
    }
  }
};
</script>
<style lang="scss">
.container-hours {
  width: 300px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #393939;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 242px;
    height: 121px;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    position: relative;
    z-index: -2;
  }
  .hours {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 22px;
    justify-content: center;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin-top: 16px;
    }
    &-info {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &-title {
        color: #ffffff;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 16px;
        }
      }
      &-subtitle {
        margin-top: 8px;
        color: #ffffff;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          margin-top: 4.5px;
          font-size: 12px;
        }
      }
    }
    &-operation {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 27px;
      @media screen and (min-width: 1280px) and (max-width: 1439px) {
        margin-top: 17px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        margin-top: 20px;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 12px;
      }
      @media screen and (min-width: 375px) and (max-width: 767px) {
        margin-top: 17px;
      }
      cursor: pointer;
      .white {
        color: #ffffff;
      }
      &-title {
        width: 234px;
        color: #ffffff;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 12px;
          width: 189px;
        }
      }
    }
    &-times {
      width: 300px;
      max-height: 280px;
      overflow: auto;
      background: #393939;
      position: relative;
      z-index: 1;
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 242px;
        max-height: 121px;
      }
      &::-webkit-scrollbar {
        width: 1px;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          width: 3px;
        }
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background: #ffffff;
      }
      .select-hour {
        background: #ffffff;
        color: #393939;
        span {
          color: #393939;
        }
      }
      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        margin-bottom: 10px;
        border-radius: 0px 0px 8px 8px;
        margin-top: 17px;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          margin-top: 10px;
        }
        &-items {
          width: 70px;
          height: 25px;
          flex-shrink: 0;
          border-radius: 2px;
          border: 1px solid #ffffff;
          background: #393939;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            text-decoration: unset;
            outline: unset;
            background-color: #ffffff;
            span {
              color: #393939;
              background-color: #ffffff;
            }
          }
          span {
            color: #ffffff;
            text-align: center;
            font-family: 'Open Sans';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &:hover {
              text-decoration: unset;
              outline: unset;
            }
          }
        }
      }
      &-description {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-bottom: 15px;
        &-item {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            color: #ffffff;
            font-family: 'Open Sans';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .active {
            width: 10px;
            height: 10px;
            flex-shrink: 0;
            border-radius: 2px;
            border: 1px solid #ffffff;
            background: #393939;
            margin-right: 4px;
          }
          .inactive {
            width: 10px;
            height: 10px;
            flex-shrink: 0;
            border-radius: 2px;
            border: 1px solid #5D5D5D;
            background: #393939;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
a.disabled {
  pointer-events: none;
  cursor: no-drop;
  background-color: #d5d5d5 !important;
  color: #000 !important;
}
.display_none {
  display: none !important;
}
</style>
