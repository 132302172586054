<template>
  <div class="container-people">
    <div class="people">
      <div class="people-info">
        <div class="people-info-title">Paso 1</div>
        <div class="people-info-subtitle">Número de Personas</div>
      </div>
      <div class="people-operation">
        <div class="people-operation-minus" :class="{ disabled: (people == 1) }" @click="subtractPeople()">
          <img src="/icons/icon_subtract.svg" alt="Precompro.com">
        </div>
        <div class="people-operation-number">{{ people }}</div>
        <div class="people-operation-max" @click="addPeople()" :class="{ disabled: (people == 6) }">
          <img src="/icons/icon_add.svg" alt="Precompro.com">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import precomproTypes from '@/types/precompro';
export default {
  name: 'peopleComponent',
  data () {
    return {
      max: 6
    };
  },
  mounted () {
    if (Object.keys(this.vendor).length > 0) {
      if (this.vendor.params.maxReservationSize !== null) {
        this.max = this.vendor.params.maxReservationSize;
      }
    }
  },
  methods: {
    subtractPeople () {
      const total = this.people;
      if (total > 1) {
        this.$store.commit(precomproTypes.mutations.setReservationPeople, total - 1);
      }
    },
    addPeople () {
      const total = this.people;
      if (total < this.max) {
        this.$store.commit(precomproTypes.mutations.setReservationPeople, total + 1);
      }
    }
  }
};
</script>

<style lang="scss">
.container-people {
  user-select: none;
  width: 300px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #393939;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 242px;
    height: 121px;
  }
  .people {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 22px;
    justify-content: center;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin-top: 16px;
    }
    &-info {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &-title {
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 16px;
        }
      }
      &-subtitle {
        margin-top: 8px;
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          margin-top: 4.5px;
          font-size: 12px;
        }
      }
    }
    &-operation {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 60px;
      margin-top: 20px;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 15.5px;
      }
      &-number {
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 21px;
        }
      }
      &-minus {
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &-max {
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .disabled {
        cursor: no-drop;
        opacity: 0.3;
      }
    }
  }
}
</style>
