<template>
  <div class="container-recommended" id="container-recommended">
    <RecommendedLoading v-if="isLoadingVendors"></RecommendedLoading>
    <RecommendedLoadingAvailability v-if="searchingAvailability && availability.length == 0"></RecommendedLoadingAvailability>
    <RecommendedItem v-if="availability.length > 0 && vendors.length == 0 && !isLoadingVendors && !searchingAvailability" :items="availability" :type="'Results'"></RecommendedItem>
    <RecommendedItem v-if="vendors.length > 0 && availability.length == 0 && !isLoadingVendors && !searchingAvailability" :items="vendors" :type="'Vendors'"></RecommendedItem>
    <RecommendedItem v-if="vendors.length == 0 && availability.length == 0 && !isLoadingVendors && !searchingAvailability" :items="vendors" :type="'Results'"></RecommendedItem>
  </div>
</template>
<script>
import RecommendedLoading from '@/components/Recommended/RecommendedLoading';
import RecommendedLoadingAvailability from '@/components/Recommended/RecommendedLoadingAvailability';
import RecommendedItem from '@/components/Recommended/RecommendedItem';
import precomproTypes from '@/types/precompro';
export default {
  name: 'recommendedComponent',
  components: { RecommendedItem, RecommendedLoading, RecommendedLoadingAvailability },
  data () {
    return {
      isLoadingVendors: true
    };
  },
  mounted () {
    this.$store.dispatch(precomproTypes.actions.searchVendors).finally(() => {
      this.isLoadingVendors = false;
    });
  }
};
</script>
<style lang="scss">
.container-recommended {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
  background: #FFF;
  box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.10);
  margin-top: 10px;
  @media screen and (min-width: 375px) and (max-width: 767px) {
    margin-top: unset;
  }
}
</style>
