<template>
  <div id="reservation-page">
    <section class="banner-principal">
      <div class="container-description">
        <p class="description">Con solo 3 pasos puede reservar en uno de nuestros restaurantes</p>
      </div>
    </section>
    <section class="container-reservation">
      <People></People>
      <Calendar></Calendar>
      <Hours :isEdit="false"></Hours>
    </section>
    <Search></Search>
    <Recommended></Recommended>
  </div>
</template>

<script>
import precomproTypes from '@/types/precompro';
import Calendar from '@/components/Banner/Calendar';
import People from '@/components/Banner/People';
import Hours from '@/components/Banner/Hours';
import Search from '@/components/Banner/Search';
import Recommended from '@/components/Recommended';

export default {
  name: 'indexView',
  components: { Calendar, People, Hours, Search, Recommended },
  mounted () {
    this.$store.commit(precomproTypes.mutations.setReservationAvailability, []);
    this.$store.commit(precomproTypes.mutations.setVendor, []);
    this.$store.commit(precomproTypes.mutations.setSource, '');
    this.$store.commit(precomproTypes.mutations.setReservationPeople, 1);
    this.$store.commit(precomproTypes.mutations.setReservationDate, null);
    this.$store.commit(precomproTypes.mutations.setReservationHours, null);
    this.$store.commit(precomproTypes.mutations.setReservationShowSearchButton, false);
  }
};
</script>
<style lang="scss">
.group-container {
  height: auto;
}
#reservation-page {
  width: 100%;
  overflow-x: auto;
  background: #FFF;
  .banner-principal {
    background-image: url('/images/new-dlk/Banner_reservas.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 355px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      height: 187px;
    }
    @media screen and (min-width: 375px) and (max-width: 767px) {
      height: 157px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      mix-blend-mode: multiply;
      z-index: 1;
    }
    .container-description {
      width: 671px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 507px;
      }
      @media screen and (min-width: 375px) and (max-width: 767px) {
        width: 311px;
      }
      .description {
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 42px;
        font-style: italic;
        font-weight: 600;
        line-height: normal;
        position: relative;
        z-index: 2;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 32px;
        }
        @media screen and (min-width: 375px) and (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
  }
  .container-reservation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -47px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    gap: 40px;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      gap: 9px;
    }
    @media screen and (min-width: 375px) and (max-width: 767px) {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}
</style>
