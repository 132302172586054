<template>
  <div class="container-calendar">
    <div class="calendar">
      <div class="calendar-info">
        <div class="calendar-info-title">Paso 2</div>
        <div class="calendar-info-subtitle">Selecciona las fechas disponibles</div>
      </div>
      <div class="calendar-operation" @click="showCalendar = !showCalendar">
        <span class="calendar-operation-title" v-if="selectedDate === null">Seleccione una fecha</span>
        <span class="calendar-operation-title" v-if="selectedDate">{{ getDateFormatView(selectedDate) }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none" v-if="!showCalendar">
          <path d="M13.7108 0.295798C13.6178 0.202069 13.5072 0.127675 13.3854 0.0769065C13.2635 0.026138 13.1328 0 13.0008 0C12.8688 0 12.7381 0.026138 12.6162 0.0769065C12.4944 0.127675 12.3838 0.202069 12.2908 0.295798L7.71079 4.87579C7.61783 4.96952 7.50723 5.04391 7.38537 5.09468C7.26351 5.14545 7.13281 5.17159 7.00079 5.17159C6.86878 5.17159 6.73808 5.14545 6.61622 5.09468C6.49436 5.04391 6.38376 4.96952 6.29079 4.87579L1.71079 0.295798C1.61783 0.202069 1.50723 0.127675 1.38537 0.0769065C1.26351 0.026138 1.1328 0 1.00079 0C0.868781 0 0.738075 0.026138 0.616216 0.0769065C0.494357 0.127675 0.383756 0.202069 0.290792 0.295798C0.104542 0.48316 0 0.736611 0 1.0008C0 1.26498 0.104542 1.51843 0.290792 1.70579L4.88079 6.29579C5.44329 6.85759 6.20579 7.17314 7.00079 7.17314C7.7958 7.17314 8.55829 6.85759 9.12079 6.29579L13.7108 1.70579C13.897 1.51843 14.0016 1.26498 14.0016 1.0008C14.0016 0.736611 13.897 0.48316 13.7108 0.295798Z" fill="white"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none" v-if="showCalendar">
          <path d="M13.7108 6.8773C13.6178 6.97103 13.5072 7.04542 13.3854 7.09619C13.2635 7.14696 13.1328 7.1731 13.0008 7.1731C12.8688 7.1731 12.7381 7.14696 12.6162 7.09619C12.4944 7.04542 12.3838 6.97103 12.2908 6.8773L7.71079 2.29731C7.61783 2.20358 7.50723 2.12918 7.38537 2.07842C7.26351 2.02765 7.13281 2.00151 7.00079 2.00151C6.86878 2.00151 6.73808 2.02765 6.61622 2.07842C6.49436 2.12918 6.38376 2.20358 6.29079 2.29731L1.71079 6.8773C1.61783 6.97103 1.50723 7.04542 1.38537 7.09619C1.26351 7.14696 1.1328 7.1731 1.00079 7.1731C0.868781 7.1731 0.738075 7.14696 0.616216 7.09619C0.494357 7.04542 0.383756 6.97103 0.290792 6.8773C0.104542 6.68994 0 6.43648 0 6.1723C0 5.90811 0.104542 5.65466 0.290792 5.4673L4.88079 0.87731C5.44329 0.315509 6.20579 -4.86374e-05 7.00079 -4.86374e-05C7.7958 -4.86374e-05 8.55829 0.315509 9.12079 0.87731L13.7108 5.4673C13.897 5.65466 14.0016 5.90811 14.0016 6.1723C14.0016 6.43648 13.897 6.68994 13.7108 6.8773Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div class="calendar-component" v-if="showCalendar" :class="{maxHei: isLargeMonth}">
      <div class="calendar-component-head">
        <div class="calendar-component-head-months" @click.prevent.stop="anteriorMes()">
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
            <path d="M3.62646 1.41071L1.00006 4.03711L3.62646 6.66351" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ prevMonth }}
        </div>
        <div class="calendar-component-head-current">{{ monthName }}</div>
        <div class="calendar-component-head-months" @click.prevent.stop="siguienteMes()">
          {{ nextMonth }}
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
            <path d="M1.37366 1.41071L4.00006 4.03711L1.37366 6.66351" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="calendar-component-body">
        <div class="header">
          <div class="header-cell">
            <span>LU</span>
          </div>
          <div class="header-cell">
            <span>MA</span>
          </div>
          <div class="header-cell">
            <span>MI</span>
          </div>
          <div class="header-cell">
            <span>JU</span>
          </div>
          <div class="header-cell">
            <span>VI</span>
          </div>
          <div class="header-cell">
            <span>SA</span>
          </div>
          <div class="header-cell">
            <span>DO</span>
          </div>
        </div>
        <div class="row" v-for="row in (isLargeMonth ? 6 : 5)" :key="'line#' + row" :style="getStyleBorder(row)">
          <div class="row-date" v-for="cell in 7" :key="'item#' + cell" :style="getStyleBorder(row, cell)" :class="{'row-select': timestamps[row - 1][cell - 1] === selectedDate}">
            <div class="row-date-empty" v-show="!dates[row - 1][cell - 1]" :style="getStyleBorder(row, cell, 1)"></div>
            <div class="row-date-info" v-if="dates[row - 1][cell - 1]" @click="setDate(timestamps[row - 1][cell - 1])">
              <span :style="{color: (timestamps[row - 1][cell - 1] < today) ? '#5E5E5E' : '#FFFFFF'}">{{days[row - 1][cell - 1]}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-component-action">
        <button @click="setDateToday()">
          <span>HOY</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import globalTypes from '@/types/global';
import _ from 'lodash';
import precomproTypes from '@/types/precompro';
export default {
  name: 'calenderComponent',
  data () {
    return {
      showCalendar: false,
      today: null,
      currentMonth: null,
      currentYear: null
    };
  },
  created () {
    const month = parseInt(this.$moment().format('M'));
    const date = this.monthConvert(month);
    this.currentMonth = date;
    const year = parseInt(this.$moment().format('YYYY'));
    this.currentYear = year;
    this.today = this.$moment().format('YYYY-MM-DD');
  },
  computed: {
    monthName () {
      return this.$moment([this.currentYear, this.currentMonth, 1]).format('MMMM');
    },
    nextMonth () {
      if (this.currentMonth === 11) {
        return this.$moment([this.currentYear, 0, 1]).format('MMMM');
      } else {
        return this.$moment([this.currentYear, this.currentMonth + 1, 1]).format('MMMM');
      }
    },
    prevMonth () {
      if (this.currentMonth === 0) {
        return this.$moment([this.currentYear, 11, 1]).format('MMMM');
      } else {
        return this.$moment([this.currentYear, this.currentMonth - 1, 1]).format('MMMM');
      }
    },
    dates () {
      const listDates = _.map(_.range(6), row => _.map(_.range(7), cell => {
        const initialDate = this.$moment([this.currentYear, this.currentMonth, 1]).startOf('month');
        const date = initialDate.clone().add(cell - initialDate.day() + (7 * row), 'days');
        return date.month() === initialDate.month() ? date : null;
      }));
      return listDates;
    },
    days () {
      return _.map(this.dates, row => _.map(row, cell => cell ? cell.format('D') : null));
    },
    timestamps () {
      return _.map(this.dates, row => _.map(row, cell => cell ? cell.format('YYYY-MM-DD') : null));
    },
    isLargeMonth () {
      return _.some(this.dates[5], date => !!date);
    }
  },
  methods: {
    getDateFormatView (date) {
      const dayText = this.$moment(date).format('dddd');
      const dayInfo = this.$moment(date).format('[,] DD [de] MMMM[,] YYYY');
      return dayText.charAt(0).toUpperCase() + dayText.slice(1) + dayInfo;
    },
    getStyleBorder (row, cell = null, empty = 0) {
      if (cell === null) {
        if (this.isLargeMonth) {
          if (row === 6) {
            return { borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' };
          } else {
            return {};
          }
        } else {
          if (row === 5) {
            return { borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' };
          } else {
            return {};
          }
        }
      } else {
        if (empty === 0) {
          const sty = {
            backgroundColor: (this.dates[row - 1][cell - 1]) ? '#393939' : '#5E5E5E',
            cursor: (this.timestamps[row - 1][cell - 1] < this.today) ? 'no-drop' : 'pointer',
            pointerEvents: (this.timestamps[row - 1][cell - 1] < this.today) ? 'none' : 'all',
            borderLeft: (cell > 1) ? '1px solid #5E5E5E' : 'unset'
          };
          if (this.isLargeMonth) {
            if (row === 6) {
              if (cell === 1) {
                sty.borderBottomLeftRadius = '8px';
              }
              if (cell === 7) {
                sty.borderBottomRightRadius = '8px';
              }

              return sty;
            } else {
              return sty;
            }
          } else {
            if (row === 5) {
              if (cell === 1) {
                sty.borderBottomLeftRadius = '8px';
              }
              if (cell === 7) {
                sty.borderBottomRightRadius = '8px';
              }

              return sty;
            } else {
              return sty;
            }
          }
        } else {
          if (this.isLargeMonth) {
            if (row === 6) {
              if (cell === 1) {
                return { borderBottomLeftRadius: '8px' };
              }
              if (cell === 7) {
                return { borderBottomRightRadius: '8px' };
              }
            } else {
              return {};
            }
          } else {
            if (row === 5) {
              if (cell === 1) {
                return { borderBottomLeftRadius: '8px' };
              }
              if (cell === 7) {
                return { borderBottomRightRadius: '8px' };
              }
            } else {
              return {};
            }
          }
        }
      }
    },
    anteriorMes () {
      this.currentMonth--;
      if (this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
    },
    siguienteMes () {
      this.currentMonth++;
      if (this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      }
    },
    getSearchTime () {
      this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, true);
      this.$store.dispatch({ type: 'global:getTimeAtention', data: { date: parseInt(this.$moment(this.date + ' 00:00:00').format('x')) } }).then(data => {
        this.$store.commit(precomproTypes.mutations.setReservationTimeAtention, data);
      }).finally(() => {
        this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
        this.$store.commit(precomproTypes.mutations.setReservationShowHours, true);
      }).catch(() => {
        this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
      });
    },
    setDate (date) {
      if (date >= this.today) {
        this.$store.commit(globalTypes.mutations.setSelectedDate, date);
        this.$store.commit(precomproTypes.mutations.setReservationDate, date);
        this.showCalendar = false;
        if (this.source !== '') {
          this.searchAvailabilityVendor();
        } else {
          this.getSearchTime();
        }
      }
    },
    setDateToday () {
      const today = this.$moment().format('YYYY-MM-DD');
      this.$store.commit(globalTypes.mutations.setSelectedDate, today);
      this.$store.commit(precomproTypes.mutations.setReservationDate, today);
      this.showCalendar = false;
      if (this.source !== '') {
        this.searchAvailabilityVendor();
      } else {
        this.getSearchTime();
      }
    },
    searchAvailabilityVendor () {
      this.$store.commit(precomproTypes.mutations.setReservationVendors, []);
      this.$store.commit(precomproTypes.mutations.setReservationAvailability, []);
      this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, true);
      this.$store.dispatch({ type: 'precompro:searchAvailability', data: { people: this.people, date: parseInt(this.$moment(this.date + ' 00:00:00').format('x')), vendorId: this.vendor.vendorId, groupId: 4 } }).then((response) => {
        this.$store.commit(precomproTypes.mutations.setReservationAvailabilityVendor, response.data);
      }).finally(() => {
        this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
        this.$store.commit(precomproTypes.mutations.setReservationShowHours, true);
      }).catch(() => {
        this.$store.commit(precomproTypes.mutations.setReservationShowProcessingHours, false);
        this.$store.commit(precomproTypes.mutations.setReservationDate, null);
        this.$store.commit(precomproTypes.mutations.setReservationHours, null);
      });
    }
  },
  watch: {
    showCalendar () {
      if (this.showCalendar) {
        this.$store.commit(precomproTypes.mutations.setReservationTimeAtention, []);
      }
    }
  }
};
</script>
<style lang="scss">
.container-calendar {
  user-select: none;
  width: 300px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #393939;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 242px;
    height: 121px;
  }
  .calendar {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 22px;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin-top: 16px;
    }
    &-info {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &-title {
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 16px;
        }
      }
      &-subtitle {
        margin-top: 8px;
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          margin-top: 4.5px;
          font-size: 12px;
        }
      }
    }
    &-operation {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 27px;
      cursor: pointer;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 20px;
      }
      &-title {
        color: #FFF;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      svg {
        width: 14.002px;
        height: 7.173px;
        flex-shrink: 0;
        fill: #FFF;
        margin-left: 20px;
      }
    }
    &-component {
      width: 300px;
      height: 284px;
      border-radius: 0px 0px 8px 8px;
      background: #393939;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      top: 21px;
      z-index: -1;
      padding-top: 16px;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        top: 12px;
        width: 242px;
        height: 250px;
      }
      &-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;
        margin: 0px 10px 10px 10px;
        &-current {
          color: #FFF;
          font-family: 'Open Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            font-size: 12px;
          }
        }
        &-months {
          color: #FFF;
          font-family: 'Open Sans';
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          cursor: pointer;
        }
      }
      &-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .header {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px 8px 0px 0px;
          border: 1px solid #5E5E5E;
          background: #393939;
          &-cell {
            width: 39px;
            height: 27px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
              width: 32px;
              height: 25px;
            }
            span {
              color: #FFF;
              text-align: center;
              font-family: 'Open Sans';
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-left: 1px solid #5E5E5E;
          border-right: 1px solid #5E5E5E;
          border-bottom: 1px solid #5E5E5E;
          background: #393939;
          &-date {
            width: 39px;
            height: 32px;
            flex-shrink: 0;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
              width: 32px;
              height: 25px;
            }
            &-empty {
              background: #5E5E5E;
              height: 100%;
              width: 100%;
            }
            &-info {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              span {
                text-align: center;
                font-family: 'Open Sans';
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
          &-select {
            .row-date-info {
              background: #FFF;
              span {
                color: #393939 !important;
                text-align: center !important;
                font-family: 'Open Sans' !important;
                font-size: 10px !important;
                font-style: normal !important;
                font-weight: 600 !important;
                line-height: normal !important;
              }
            }
          }
        }
      }
      &-action {
        margin-top: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 39px;
          height: 32px;
          flex-shrink: 0;
          border-radius: 6px;
          border: 1px solid #5E5E5E;
          background: #FFF;
          outline: none;
          cursor: pointer;
          span {
            color: #393939;
            text-align: center;
            font-family: 'Open Sans';
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
.maxHei {
  @media screen and (max-width: 1024px) {
    height: 324px !important;
  }
  @media screen and (max-width: 768px) {
    height: 285px !important;
  }
}
</style>
